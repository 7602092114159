<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Логи'" :breadCrumbFirstLink="'/'" :title="'Логи'">
      <div class="form-create__buttons"></div>
    </ToolsPanel>
    <ContentContainer>
      <div class="logs">
        <div class="logs__title">Logs Creation Video</div>

        <div v-for="log in logs" :key="log.id" class="logs__item">
          <div class="logs__top">
            <div>Flat ID: {{ log.flatId }}</div>
            <div>Feed ID: {{ log.feedId }}</div>
            <div>
              {{ formatDate(log.elasticCreatedAt) }}
            </div>
          </div>
          <div class="logs__list-item">
            Количество потраченных кредитов: <b>{{ log.countCreditsWrittenOff }}</b>
          </div>
          <div class="logs__list-item">
            Ссылка на видео: <b>{{ log.storageVideoUrl }}</b>
          </div>
        </div>
      </div>
    </ContentContainer>
  </Main>
</template>

<script>
import Main from "~/templates/Main.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import { getStoredUserInfo } from "~/helpers/userAuth";
import usersApi from "~/api/user";
import moment from "moment";

export default {
  name: "Logs",
  metaInfo: {
    title: "Логи",
  },
  data() {
    return {
      logs: [],
      user: {},
    };
  },
  components: {
    Main,
    ToolsPanel,
    ContentContainer,
  },
  mounted() {
    this.getItem();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getItem() {
      if (getStoredUserInfo()) {
        this.user = getStoredUserInfo();

        const url = `/${this.user.developerId}?relations[0]=creditUsageLogs`;
        try {
          usersApi.getItemDeveloper(url).then((res) => {
            this.logs = res.data.creditUsageLogs;
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logs {
  &__title {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &__item {
    padding: 15px 15px 5px;
    margin-bottom: 10px;
    background: rgb(238, 238, 238);
    border-radius: 10px;
  }

  &__top {
    display: flex;
    padding-bottom: 15px;

    div {
      margin-right: 30px;
      font-size: 18px;
    }
  }

  &__list-item {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
</style>
